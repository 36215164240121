import React, { useState, useEffect } from "react";
import "../../index.css";
import {
  UserOutlined,
  BranchesOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import QeteroFooter from "../Footer/QeteroFooter";
import QeteroHeader from "../Header/QeteroHeader";
import QeteroService from "./QeteroService";

function LandingPage() {
  const [language, setLanguage] = useState(localStorage.getItem("language") || "en");

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    localStorage.setItem("language", selectedLanguage);
    setLanguage(selectedLanguage);
    // Here you would typically trigger a language change in your app's localization
  };

  useEffect(() => {
    // Handle any language-related logic here, e.g., updating translations
  }, [language]);

  return (
    <div className="bg-gray-50">
         <select
            onChange={handleLanguageChange}
            value={language}
            className="p-2 border rounded"
          >
            <option value="en">English</option>
            <option value="am">Amharic</option>
          </select>
        <QeteroHeader />
      <div className="relative">
   
        <div className="absolute top-4 left-4 z-10">
    
        </div>
      </div>
      <div className="pt-20 px-4 py-10 bg-gradient-to-r relative">
        <div className="flex flex-col md:flex-row mt-20 md:ml-28 md:max-w-[740px]">
          <div className="text-5xl font-bold md:text-4xl">
            <p className="leading-relaxed">
              Save Time: Your
              <br />
              Convenient Way to
              <br />
              Schedule Your Services
            </p>
          </div>
        </div>
        <img
          src="./people-using-online-appointment-concept-600nw-2094110803-removebg-preview.png"
          alt="Icon"
          className="absolute top-1/2 right-48 transform -translate-y-1/2 object-cover mt-20 hidden md:block"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 divide-y md:divide-y-0 md:divide-x mt-32 gap-8 bg-white py-10">
        <div className="flex items-center justify-center md:ml-28">
          <div className="text-5xl text-blue-500">
            <UserOutlined className="text-pink-700" />
          </div>
          <div className="ml-8 md:ml-20">
            <div className="text-3xl font-bold text-gray-800">15,000+</div>
            <div className="mt-3 text-lg text-gray-500">Users</div>
          </div>
        </div>
        <div className="flex items-center justify-center">
          <div className="text-5xl text-blue-500">
            <BranchesOutlined className="text-pink-700" />
          </div>
          <div className="ml-8 md:ml-20">
            <div className="text-3xl font-bold text-gray-800">10+</div>
            <div className="mt-3 text-lg text-gray-500">Services</div>
          </div>
        </div>
        <div className="flex items-center justify-center">
          <div className="text-5xl text-blue-500">
            <UnorderedListOutlined className="text-pink-700" />
          </div>
          <div className="ml-8 md:ml-20">
            <div className="text-3xl font-bold text-gray-800">15+</div>
            <div className="mt-3 text-lg text-gray-500">Partners</div>
          </div>
        </div>
      </div>
      <div className="text-center mt-20">
        <h2 className="text-4xl font-semibold text-gray-900 ml-16">
          Our Partners
        </h2>
        <div className="mt-10 flex flex-wrap justify-center gap-6 px-4">
          <QeteroService
            title="Dars"
            description="Vehicle sales, residence sales, business sales, other property sales, vehicle spare parts sales, lease sales"
            className="w-full md:w-1/3 lg:w-1/4"
          />
          <QeteroService
            title="Electric Utility"
            description="Vehicle sales, residence sales, business sales, other property sales, vehicle spare parts sales, lease sales"
            className="w-full md:w-1/3 lg:w-1/4"
          />
          <QeteroService
            title="Hospital Appointment"
            description="Vehicle sales, residence sales, business sales, other property sales, vehicle spare parts sales, lease sales"
            className="w-full md:w-1/3 lg:w-1/4"
          />
        </div>
      </div>

      {/* Latest News Section */}
      <div className="py-16">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-semibold text-gray-900">Latest News</h2>
          <p className="text-lg text-gray-700 mt-2">
            Stay informed with the latest updates and announcements from us.
          </p>
        </div>
        <div className="flex flex-wrap justify-center gap-8 px-4">
          {[
            {
              img: "./qetero-meeting.jpg",
              title: "DARS and qetero Agreement",
              description:
                "Save time with streamlined services. No more waiting in line! Schedule your appointment online at any time, day or night.",
            },
            {
              img: "./qetero-survey.jpg",
              title: "DARS and qetero Agreement",
              description:
                "Save time with streamlined services. No more waiting in line! Schedule your appointment online at any time, day or night.",
            },
            {
              img: "./dars-trust.jpg",
              title: "Trustworthy Services",
              description:
                "Save time with streamlined services. No more waiting in line! Schedule your appointment online at any time, day or night.",
            },
            {
              img: "./dars-blood.jpg",
              title: "We are Responsible",
              description:
                "Save time with streamlined services. No more waiting in line! Schedule your appointment online at any time, day or night.",
            },
          ].map((news, index) => (
            <div
              key={index}
              className="bg-white hover:shadow-xl transition-shadow duration-300 transform hover:scale-105 w-full md:w-80"
            >
              <img
                className="w-full h-48 object-cover"
                src={news.img}
                alt={news.title}
              />
              <div className="p-6">
                <h3 className="text-xl font-semibold text-gray-800 mb-2">
                  {news.title}
                </h3>
                <p className="text-base text-gray-600">{news.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <QeteroFooter />
    </div>
  );
}

export default LandingPage;
