import * as React from "react";
import { useNavigate } from "react-router-dom";
function PIN() {
    const navigate = useNavigate();
      const handleForgotPin = () => {
    navigate("/ForgetPIN");
    };
   
  return (
    <div className="flex flex-col px-16 py-6 bg-white max-md:px-5">
      <div className="flex gap-2.5 self-start text-xl font-medium text-center text-indigo-500 whitespace-nowrap">
        <img
          loading="lazy"
          srcSet="./image1.png"
          className="shrink-0 aspect-[0.96] w-[57px]"
        /> 
        <div className="my-auto">DARS.</div>
      </div>
      <div className="self-center mt-11 text-3xl font-bold text-black max-md:mt-10">
        Login
      </div>
      <div className="self-center mt-11 w-full max-w-[947px] max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
        <div className="flex justify-start w-full max-md:ml-0 max-md:w-full">
            <img
              loading="lazy"
              srcSet="/images11.jpg"
              className="w-[59%] aspect-[0.96] max-md:mt-10 max-md:max-w-full"
            />
          </div>
          <div className="flex flex-col ml-5 w-[41%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow py-0.5 text-xs max-md:mt-10">            
           <label htmlFor="phoneNumber" className="block text-gray-700 mt-8">PIN</label>
            <input type="password" id="phoneNumber" name="phoneNumber" className="w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                          <div className="shrink-0 mt-2.5 h-0.5 bg-indigo-500" />
                           <div className="flex justify-end mt-2  text-sm  cursor-pointer"  onClick={handleForgotPin}>Forgot your Pin?</div>
                <button className="justify-center items-center px-12 py-3.5 mt-28 text-sm font-semibold text-center text-white whitespace-nowrap bg-indigo-500 rounded-lg max-md:px-5 max-md:mt-10" onClick={() => navigate('/')}>
                Login
              </button>
              <div className="justify-center items-end px-16 py-7 mt-28 bg-slate-50 max-md:px-5 max-md:mt-10">
                <span className="">Didn't have an account? </span>
                <button className="text-indigo-500 underline" onClick={() => navigate('/SignUp')}>Create an account</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PIN;


