import React from "react";
import "../../index.css";
import {
  ClockCircleOutlined,
  BellOutlined,
  CreditCardOutlined,
  CheckCircleOutlined,
  CalendarOutlined,
  MobileOutlined,
} from "@ant-design/icons";

const Cards = ({ title, description, icon }) => {
  const getIcon = () => {
    switch (icon) {
      case "clock":
        return <ClockCircleOutlined />;
      case "bell":
        return <BellOutlined />;
      case "card":
        return <CreditCardOutlined />;
      case "circle":
        return <CheckCircleOutlined />;
      case "calander":
        return <CalendarOutlined />;
      case "mobileoutlined":
        return <MobileOutlined />;
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col w-3/12 max-md:ml-0 max-md:w-full">
      <div className="flex flex-col grow justify-center text-stone-900 max-md:mt-10 ml-24 mt-20">
        <div className="flex flex-col px-5 py-10 bg-white rounded-xl shadow-lg w-18 ">
          <div className="text-7xl text-indigo-500 ml-16">{getIcon()}</div>
          <div className="mt-5 text-xl font-bold text-center">{title}</div>
          <div className="mt-5 text-base">{description}</div>
        </div>
      </div>
    </div>
  );
};

export default Cards;
