import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { jwtDecode } from "jwt-decode";

const SignUp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { mobile } = location.state || {};
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    try {
      const response = await fetch(`https://qetero.com/api/v1/auth/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mobile: `+251${mobile}`,
          firstname: firstName,
          lastname: lastName,
          password,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("token", data);
        const decodedToken = jwtDecode(data);

        if (decodedToken) {
          localStorage.setItem("token", data); // Store the token in localStorage
          navigate("/"); // Navigate to the home page
        }
      } else {
        throw new Error("Failed to register");
      }
    } catch (error) {
      console.error("Error registering:", error);
      setError(error.message || "Failed to register. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="flex flex-1 h-screen bg-slate-50 justify-center">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <a
          href="#"
          className="flex items-center mb-6 text-2xl font-semibold text-gray-900"
        >
          <img className="w-8 h-8 mr-2" src="/images11.jpg" alt="logo" />
          DARS
        </a>
        <form
          className="max-w-sm bg-white flex flex-col justify-center text-center mx-auto p-12 rounded-lg"
          onSubmit={handleSubmit}
        >
          <header className="mb-8">
            <h1 className="text-2xl font-bold mb-1">Create an Account</h1>
          </header>
          <label
            htmlFor="first-name-input"
            className="block mb-2 text-start text-sm font-medium text-gray-900"
          >
            First Name:
          </label>
          <input
            type="text"
            id="first-name-input"
            className={`bg-gray-50 border ${
              error ? "border-red-500" : "border-gray-300"
            } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
          <label
            htmlFor="last-name-input"
            className="block mt-4 mb-2 text-start text-sm font-medium text-gray-900"
          >
            Last Name:
          </label>
          <input
            type="text"
            id="last-name-input"
            className={`bg-gray-50 border ${
              error ? "border-red-500" : "border-gray-300"
            } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />

          {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
          <button
            type="submit"
            className="text-white w-full mt-12 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            disabled={isLoading}
          >
            {isLoading ? "Registering..." : "Sign Up"}
          </button>
        </form>
      </div>
    </section>
  );
};

export default SignUp;
