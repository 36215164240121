import React from "react";
import "../../index.css";

const Services = ({ title, description }) => {
  return (
    <div className="flex flex-col w-3/12 max-md:ml-0 max-md:w-full">
      <div className="flex flex-col grow justify-center text-stone-900 max-md:mt-10 ml-24 mt-5">
        <div className="flex flex-col px-5 py-10 bg-white rounded-xl shadow-lg w-full">
          <div className="mt-7 text-xl font-bold text-center">{title}</div>
          <div className="mt-5 text-base">{description}</div>
        </div>
      </div>
    </div>
  );
};

export default Services;
