import React, { useState, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ErrorMessage from "../Commons/ErrorMessage";
import languages from "../language.json"; // Import the language JSON

export default function SelectLabels({ data, setData, errors }) {
  const [locationOptions, setLocationOptions] = useState([]);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [language, setLanguage] = useState("en"); // Default language

  useEffect(() => {
    // Retrieve language from localStorage
    const storedLanguage = localStorage.getItem("language") || "en";
    setLanguage(storedLanguage);
  }, []);

  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await fetch("https://qetero.com/api/v1/branches");
        const data = await response.json();

        const branches = data.data || [];

        const locations = branches.map((branch) => ({
          id: branch.id,
          name: languages[language].locations[branch.name] || branch.name, // Translate location names
        }));

        setLocationOptions(locations);
      } catch (error) {
        console.error("Error fetching locations:", error);
      }
    };

    fetchBranches();
  }, [language]);

  useEffect(() => {
    if (data.location?.id) {
      fetch(
        `https://qetero.com/api/v1/branch_services/findServicesByBranchGuest?branch_id=${data.location.id}`
      )
        .then((response) => response.json())
        .then((_data) => {
          const services = _data.services.map((service) => ({
            id: service.id,
            name: languages[language].services[service.name] || service.name, // Translate service names
          }));
          setServiceOptions(services);
        })
        .catch((error) => {
          console.error("Error fetching services:", error);
        });
    } else {
      setServiceOptions([]);
    }
  }, [data.location, language]);

  const handleLocationChange = (event) => {
    const selectedLocation = locationOptions.find(
      (opts) => opts.id === event.target.value
    );
    setData({
      ...data,
      location: selectedLocation,
      service: {}, // Reset service when location changes
    });
    setServiceOptions([]); // Clear service options when location changes
  };

  const handleServiceChange = (event) => {
    const selectedService = serviceOptions.find(
      (opts) => opts.id === event.target.value
    );
    setData({
      ...data,
      service: selectedService,
    });
  };

  return (
    <div className="flex flex-col md:flex-row items-center mt-16 gap-8 px-4 md:px-28">
      <div className="w-full md:w-1/3">
        <ErrorMessage message={errors?.location} />
        <FormControl className="w-full">
          <InputLabel id="location-select-label">{languages[language].selectLocation}</InputLabel>
          <Select
            labelId="location-select-label"
            id="location-select"
            value={data.location?.id || ""}
            label={languages[language].selectLocation}
            onChange={handleLocationChange}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                  overflow: "auto",
                },
              },
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            }}
          >
            <MenuItem value="">
              <em>{languages[language].selectLocation}</em>
            </MenuItem>
            {locationOptions.map((location) => (
              <MenuItem key={location.id} value={location.id}>
                {location.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className="w-full md:w-1/3">
        <ErrorMessage message={errors?.service} />
        <FormControl className="w-full">
          <InputLabel id="service-select-label">{languages[language].selectService}</InputLabel>
          <Select
            labelId="service-select-label"
            id="service-select"
            value={data.service?.id || ""}
            label={languages[language].selectService}
            onChange={handleServiceChange}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                  overflow: "auto",
                },
              },
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            }}
          >
            <MenuItem value="">
              <em>{languages[language].selectService}</em>
            </MenuItem>
            {serviceOptions.map((service) => (
              <MenuItem key={service.id} value={service.id}>
                {service.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
}