import React from 'react';
import Header from '../../Components/Header/QeteroHeader';
import Footer from '../Footer/Footer';

const AboutUs = () => {
  return (
    <div className="bg-gray-100 mt-12">
      <Header />
      <div className="container mx-auto py-16 px-6 md:px-12">
        <h1 className="text-5xl font-extrabold text-center mb-12 text-gray-800">Who We Are</h1>
        <p className="text-lg text-center mb-16 text-gray-600">
          The Documents Authentication and Registration Office is committed to implementing ISO 9001:2008 QMS and providing the highest quality and credible Documents Authentication and Registration service to all customers, conforming to customer and legal requirements, with a focus on continual improvement and excellence.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          <div className="text-center p-8 bg-white shadow-lg rounded-lg transform transition-transform hover:-translate-y-2">
            <div className="mb-4">
              <svg className="w-12 h-12 mx-auto text-indigo-500" fill="currentColor" viewBox="0 0 24 24">
                <path d="M12 2a10 10 0 11-10 10A10 10 0 0112 2zm1 15h-2v-2h2zm0-4h-2V7h2z"></path>
              </svg>
            </div>
            <h2 className="text-2xl font-bold mb-4 text-indigo-700">Mission</h2>
            <p className="text-gray-600">
              Our mission is to provide reliable means of evidence through the authentication and registration of documents, facilitating relationships at domestic and international levels, supporting the justice system, and ensuring the availability of documents whenever required.
            </p>
          </div>
          <div className="text-center p-8 bg-white shadow-lg rounded-lg transform transition-transform hover:-translate-y-2">
            <div className="mb-4">
              <svg className="w-12 h-12 mx-auto text-green-500" fill="currentColor" viewBox="0 0 24 24">
                <path d="M12 2a10 10 0 11-10 10A10 10 0 0112 2zm1 15h-2v-2h2zm0-4h-2V7h2z"></path>
              </svg>
            </div>
            <h2 className="text-2xl font-bold mb-4 text-green-700">Vision</h2>
            <p className="text-gray-600">
              Our vision is to become a model institution by 2014, satisfying customers through authenticated and registered document services that adhere to international standards.
            </p>
          </div>
          <div className="text-center p-8 bg-white shadow-lg rounded-lg transform transition-transform hover:-translate-y-2">
            <div className="mb-4">
              <svg className="w-12 h-12 mx-auto text-red-500" fill="currentColor" viewBox="0 0 24 24">
                <path d="M12 2a10 10 0 11-10 10A10 10 0 0112 2zm1 15h-2v-2h2zm0-4h-2V7h2z"></path>
              </svg>
            </div>
            <h2 className="text-2xl font-bold mb-4 text-red-700">Core Values</h2>
            <p className="text-gray-600">
              We believe the customer is king. Our core values include honesty, integrity, transparency, quality service, and customer satisfaction. We are committed to teamwork, continuous improvement, gender equality, and good governance.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
