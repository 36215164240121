import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");
  const [codeSent, setCodeSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const phonePattern = /^[0-9]{9}$/;
    if (!phonePattern.test(phoneNumber)) {
      setError("Please enter a valid phone number.");
      return;
    }
    setError("");
    setIsLoading(true);

    try {
      const response = await fetch(`https://qetero.com/api/v1/auth/sendOTP`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ mobile: `+251${phoneNumber}` }),
      });

      if (!response.ok) {
        throw new Error("Failed to send verification code");
      }

      setCodeSent(true);
      navigate("/otp", {
        state: {
          mobile: phoneNumber,
        },
      });
    } catch (error) {
      console.error("Error sending verification code:", error);
      setError("Failed to send verification code. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="flex flex-1 h-screen bg-slate-50 justify-center">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <a
          href="#"
          className="flex items-center mb-6 text-2xl font-semibold text-gray-900"
        >
          <img className="w-8 h-8 mr-2" src="/images11.jpg" alt="logo" />
          DARS
        </a>
        <form
          className="max-w-sm bg-white flex flex-col justify-center text-center mx-auto p-12 rounded-lg"
          onSubmit={handleSubmit}
        >
          <header className="mb-8">
            <h1 className="text-2xl font-bold mb-1">Enter your phone Number</h1>
          </header>
          <label
            htmlFor="phone-input"
            className="block mb-2 text-start text-sm font-medium text-gray-900"
          >
            Phone number:
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 start-0 top-0 flex items-center pl-3.5 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 19 18"
              >
                <path d="M18 13.446a3.02 3.02 0 0 0-.946-1.985l-1.4-1.4a3.054 3.054 0 0 0-4.218 0l-.7.7a.983.983 0 0 1-1.39 0l-2.1-2.1a.983.983 0 0 1 0-1.389l.7-.7a2.98 2.98 0 0 0 0-4.217l-1.4-1.4a2.824 2.824 0 0 0-4.218 0c-3.619 3.619-3 8.229 1.752 12.979C6.785 16.639 9.45 18 11.912 18a7.175 7.175 0 0 0 5.139-2.325A2.9 2.9 0 0 0 18 13.446Z" />
              </svg>
            </div>
            <input
              type="text"
              id="phone-input"
              aria-describedby="helper-text-explanation"
              className={`bg-gray-50 border ${
                error ? "border-red-500" : "border-gray-300"
              } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5`}
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              pattern="[0-9]{9}"
              placeholder="911111111"
              required
            />
          </div>
          {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
          <button
            type="submit"
            className="text-white w-full mt-12 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            disabled={isLoading}
          >
            {isLoading ? "Sending..." : "Send Verification Code"}
          </button>
          {codeSent && (
            <p className="text-green-500 text-sm mt-2">
              Verification code sent!
            </p>
          )}
        </form>
      </div>
    </section>
  );
};

export default Login;
