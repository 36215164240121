import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table } from "antd";
import Header from '../../Components/Header/QeteroHeader';
import { jwtDecode } from "jwt-decode";

const BookingHistory = () => {
  const [bookingHistory, setBookingHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [userId, setUserId] = useState("");
  const [loading, setLoading] = useState(false); // Loading state


  const decodeToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        console.log(decodedToken, "Decoded Token");
        const userIdFromToken = decodedToken?.data?.user?.id;
        setUserId(userIdFromToken);
        console.log(userIdFromToken, "User ID extracted from token");
      } catch (error) {
        console.error("Failed to decode token.", error);
      }
    }
  };

  const fetchAppointments = async (page = 1, limit = 10) => {
    setLoading(true); // Start loading
    try {
      const token = localStorage.getItem("token");
      console.log(userId, "Here We go");

      const response = await axios.get(
        `https://qetero.com/api/v1/appointments/findAppointmentsByUser?created_by=${userId}&page=${page}&limit=${limit}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data?.data?.data) {
        setBookingHistory(response.data.data.data);
        setTotalItems(Number(response.data.data.totalItems));
      }
    } catch (error) {
      console.error("Fetching appointments failed.", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    decodeToken(); // Decode the token on component mount
  }, []);

  useEffect(() => {
    if (userId) {
      fetchAppointments(currentPage, pageSize);
    }
  }, [currentPage, pageSize, userId]);

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const columns = [
    { title: "CNR", dataIndex: "cnr", key: "appointment_id" },
    { title: "Service", dataIndex: "service_name", key: "service_name" },
    { title: "Status", dataIndex: "status", key: "status" },
    { title: "Booked By", dataIndex: "firstname", key: "firstname" },
    { title: "Date/Time", dataIndex: "appointment_time", key: "appointment_time" },
  ];

  const paginationConfig = {
    current: currentPage,
    pageSize: pageSize,
    total: totalItems,
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "30"],
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
  };

  return (
    <div>
      <Header />
      <h2 className="font-bold ml-16 text-xl mt-12">My Bookings</h2>
      <Table
        dataSource={bookingHistory}
        columns={columns}
        pagination={paginationConfig}
        onChange={handleTableChange}
        loading={loading} // Loading prop
        className="ml-16 mt-12"
      />
    </div>
  );
};

export default BookingHistory;
