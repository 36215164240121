import React from "react";
import { useNavigate } from "react-router-dom";
import "../../index.css";


const Services = ({ title, description, language }) => {
  const navigate = useNavigate();

  const handleVisitWebsite = () => {
    navigate("/appointment"); // Redirects to the landing page
  };

  return (
    <div className="flex flex-col w-3/12 max-md:ml-0 max-md:w-full">
      <div className="flex flex-col grow justify-center text-stone-900 max-md:mt-10 ml-24 mt-5">
        <div className="flex flex-col px-5 py-10 bg-white rounded-xl w-full">
          <div className="mt-7 text-xl font-bold text-center">{title}</div>
          <div className="mt-5 text-base">{description}</div>
          <button
            onClick={handleVisitWebsite}
            className="justify-center items-center px-10 py-3 text-base font-bold leading-6 text-white bg-pink-700 rounded-lg max-w-[250px] mt-5"
          >
          Book Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default Services;
