import React from "react";

const SuccessPaymentModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-indigo-900 bg-opacity-50 backdrop-blur-sm">
      <div className="bg-white rounded-lg shadow-lg p-8 w-full max-w-md">
        <h2 className="text-2xl font-bold mb-4">Payment Successful!</h2>
        <p className="text-lg mb-4">Appointment booked successfully.</p>
        <div className="flex justify-center mt-4">
          <button
            className="bg-indigo-600 w-full text-white font-bold py-2 px-4 rounded"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessPaymentModal;
