import React from "react";
import "../../index.css";
import { FacebookOutlined } from "@ant-design/icons";
import { TwitterOutlined } from "@ant-design/icons";
import { LinkedinOutlined } from "@ant-design/icons";

function Footer() {
  return (
    <div className=" mt-48">
      <div className=" flex flex-col px-14 pt-12 pb-4 bg-white max-md:px-5 ">
        <div className="self-center w-full max-w-[1128px] max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-[30%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow mt-1.5 text-base text-black max-md:mt-10">
                <div className="font-bold uppercase">Navigation</div>
                <div className="mt-6 leading-8">
                  Home <br />
                  Contact us
                  <br />
                  About Us
                  <br />
                </div>
              </div>
            </div>
            <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col mt-1 text-base text-black max-md:mt-10">
                <div className="font-bold uppercase">What We Do</div>
                <div className="mt-7 leading-8">
                  Document Authentication Appointement
                  <br />
                  Hospital Appointement
                  <br />
                  Bill Payment Appointment
                  <br />
                  Other Services
                  <br />
                  <br />
                </div>
              </div>
            </div>
            <div className="flex flex-col ml-5 w-[26%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col text-base text-black max-md:mt-10">
                <div className="font-bold uppercase">LEGAL</div>
                <div className="mt-7 leading-8">
                  General Info
                  <br />
                  Privacy Policy
                  <br />
                  Terms of Service
                </div>
              </div>
            </div>
            <div className="flex flex-col ml-5 w-[11%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col mt-1 text-base text-black max-md:mt-10">
                <div className="font-bold uppercase">TALK TO US</div>
                <div className="mt-6 leading-8">
                  Contact Us
                  <br />
                  Facebook
                  <br />
                  Linkedin
                  <br />
                  Twitter
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shrink-0 self-end mt-28 max-w-full h-px bg-black w-[1209px] max-md:mt-10" />
        <div className="flex gap-5 justify-between mt-2 w-full text-center max-md:flex-wrap max-md:max-w-full">
          <div className="flex gap-2.5 text-xl font-medium text-indigo-500 whitespace-nowrap">
            <img
              loading="lazy"
              srcSet="./WhatsApp_Image_2024-09-10_at_09.42.40_e45954ba-removebg-preview.png"
              className="shrink-0 aspect-[0.96] w-[64px]"
              alt="Qetero-logo"
            />
          </div>
          <div className="flex gap-5 self-start mt-3.5 text-sm leading-4 text-black max-md:flex-wrap max-md:max-w-full">
            <div className="flex-auto my-auto text-base">
              © 2024. All Rights Reserved.{" "}
            </div>

            <FacebookOutlined />
            <TwitterOutlined />
            <LinkedinOutlined />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
