import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { useSearchParams } from 'react-router-dom';

const SuccessMessage = () => {
  const [searchParams] = useSearchParams();
  const cnr = searchParams.get('merch_order_id');
  return (
    <div>
      <Header />
      <div className="flex flex-col px-5 max-w-[749px] text-stone-900">
       
      </div>
      <div className="flex justify-center w-full mt-8">
        <div className="w-full max-w-[749px] bg-white rounded-lg flex max-md:flex-col">
          <div className="bg-indigo-500  rounded-l-lg p-12 flex flex-col pt-12  max-md:rounded-l-lg max-md:rounded-r-none max-md:pb-12">
            <div className="flex flex-col px-10 text-base text-white max-md:px-5 ">
              <div className="text-3xl font-semibold">Appointment Successfull</div>   
              <div className="text-3xl font-semibold">Your Payement Order Number: {cnr && cnr}</div>             
            </div>
           
          </div>
         
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SuccessMessage;