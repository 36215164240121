import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import "../../index.css";
import { FaBars, FaTimes } from "react-icons/fa";
import { AuthContext } from "../../contexts/authContext";
import { CgProfile } from "react-icons/cg";

function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const { user, setUser, setToken } = useContext(AuthContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  console.log("user", user);

  const linkClasses = (path) => {
    const isActive = location.pathname === path;
    return isActive
      ? "text-black font-bold text-base my-2"
      : "hover:text-black text-base my-2 transition-colors duration-200 ease-in-out";
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    setUser(null);
    setToken(null);
    navigate("/");
  };

  const handleNavigation = (path) => {
    const token = localStorage.getItem("token");
    if (path === "/appointment" && !token) {
      navigate("/Login");
    } else {
      navigate(path);
    }
    setMenuOpen(false);
  };

  return (
    <div className="bg-white">
      <div className="flex justify-between items-center p-5 max-w-7xl mx-auto">
        <div className="flex items-center">
          <img
            loading="lazy"
            src="./image1.png"
            className="shrink-0 aspect-[0.96] w-[57px]"
            alt="DARS Logo"
          />
          <div className="ml-3 text-2xl font-semibold text-indigo-600">
            DARS.
          </div>
        </div>
        <div className="hidden md:flex gap-5 items-center text-sm leading-4 text-slate-500">
          <Link to="/LandingPage" className={linkClasses("/")}>
            Home
          </Link>
          <button
            onClick={() => handleNavigation("/appointment")}
            className={linkClasses("/appointment")}
          >
            Book an appointment
          </button>
          <Link to="/contactus" className={linkClasses("/contactus")}>
            Contact Us
          </Link>
          <Link to="/aboutus" className={linkClasses("/aboutus")}>
            About Us
          </Link>

          <>
          {user ? (
          <Link to="/Profile" className={linkClasses("/Profile")}>
            Profile
          </Link>
            ) : null}
  </>
          <>
    {user ? (
      <Link to="/BookingHistory" className={linkClasses("/BookingHistory")}>
        Booking History
      </Link>
    ) : null}
  </>

        </div>
        <div className="hidden md:flex gap-5 items-center">
          {user ? (
            <div className="relative">
              <div
                class="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-blue-200 rounded-full hover:border-spacing-1"
                onClick={toggleDropdown}
              >
                <a class="font-medium text-gray-600 ">
                  {user?.firstname?.charAt(0)?.toUpperCase()}
                  {user?.lastname?.charAt(0)?.toUpperCase()}
                </a>
              </div>
              {dropdownOpen && (
                <div className="absolute right-0 mt-2   bg-white rounded-md shadow-xl z-1000">
                  <div class="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 ">
                    <div class="px-4 py-3 text-sm text-gray-900">
                      <div>
                        {user?.firstname} {user?.lastname}
                      </div>
                      <div class="font-medium truncate">{user?.mobile}</div>
                    </div>
                    <div class="py-1">
                      <a
                        href="#"
                        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600  dark:hover:text-white"
                        onClick={handleLogout}
                      >
                        Sign out
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <>
              <button
                className="bg-indigo-500 text-white rounded-md px-4 py-2 hover:bg-indigo-600 transition-colors duration-200 ease-in-out"
                onClick={() => navigate("/Login")}
              >
                Login
              </button>
            </>
          )}
        </div>
        <div className="md:hidden flex items-center">
          <button onClick={toggleMenu} className="text-2xl">
            {menuOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>
      </div>
      {menuOpen && (
        <div className="md:hidden bg-white shadow-md p-5">
          <button
            className="w-full text-left text-base my-2 hover:text-black transition-colors duration-200 ease-in-out"
            onClick={() => handleNavigation("/LandingPage")}
          >
            Home
          </button>
          <button
            className="w-full text-left text-base my-2 hover:text-black transition-colors duration-200 ease-in-out"
            onClick={() => handleNavigation("/appointment")}
          >
            Book an appointment
          </button>
          <button
            className="w-full text-left text-base my-2 hover:text-black transition-colors duration-200 ease-in-out"
            onClick={() => handleNavigation("/contactus")}
          >
            Contact Us
          </button>
          <button
            className="w-full text-left text-base my-2 hover:text-black transition-colors duration-200 ease-in-out"
            onClick={() => handleNavigation("/aboutus")}
          >
            About Us
          </button>
          <button
            className="w-full text-left text-base my-2 hover:text-black transition-colors duration-200 ease-in-out"
            onClick={() => handleNavigation("/Profile")}
          >
            Profile
          </button>
          <button
            className="w-full text-left text-base my-2 hover:text-black transition-colors duration-200 ease-in-out"
            onClick={() => handleNavigation("/bookinghistory")}
          >
            Booking History
          </button>
          {!user ? (
            <>
              <button
                className="bg-indigo-500 text-white rounded-md w-full py-2 mt-2 hover:bg-indigo-600 transition-colors duration-200 ease-in-out"
                onClick={() => handleNavigation("/SignUp")}
              >
                Sign Up
              </button>
              <button
                className="bg-indigo-500 text-white rounded-md w-full py-2 mt-2 hover:bg-indigo-600 transition-colors duration-200 ease-in-out"
                onClick={() => handleNavigation("/Login")}
              >
                Sign In
              </button>
            </>
          ) : (
            <>
              <button
                className="bg-indigo-500 text-white rounded-md w-full py-2 mt-2 hover:bg-indigo-600 transition-colors duration-200 ease-in-out"
                onClick={toggleDropdown}
              >
                {user?.firstname}
                {user?.lastname}
              </button>
              {dropdownOpen && (
                <>
                  <button
                    className="w-full text-left text-base my-2 hover:text-black transition-colors duration-200 ease-in-out"
                    onClick={handleLogout}
                  >
                    Logout
                  </button>
                </>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default Header;
