import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../../index.css";
import "react-calendar/dist/Calendar.css";
import LocationDropdown from "./Locationdropdown";
import Calander from "./Calander";
import Footer from "../Footer/Footer";
import Header from "../../Components/Header/QeteroHeader";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import languages from "../language.json"; // Import the language JSON

function Appointment() {
  const [data, setData] = useState({
    date: null,
    time: null,
    location: "",
    service: "",
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [firstname, setFirstname] = useState("");
  const [id, setId] = useState("");
  const [language, setLanguage] = useState("en"); // Default language

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Retrieve language from localStorage
    const storedLanguage = localStorage.getItem("language");
    console.log(storedLanguage, 'Stored');
    
    if (storedLanguage) {
      setLanguage(storedLanguage);
    }

    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token); // Decode the token
        setFirstname(decodedToken?.data?.user?.firstname || "");
        setId(decodedToken?.data?.user?.id || decodedToken?.data?.id || "");
      } catch (error) {
        console.error("Failed to decode token:", error);
        navigate("/Login");
      }
    } else {
      navigate("/Login");
    }
  }, [navigate]);

  const addAppointment = async () => {
    setIsLoading(true);
    const [hours, minutes] = data.time.time_from.split(":");
    const date = new Date(data.date);
    date.setHours(hours);
    date.setMinutes(minutes);

    const _data = {
      appointment_time: date.toISOString(),
      reschedule_count: 0,
      appointment_through: "self",
      branch_service_id: data.service.id,
      appointment_date: date.toISOString().split("T")[0],
      appointment_duration_id: data.time.duration_id,
      created_by: id,
    };
    try {
      const response = await axios.post(
        "https://qetero.com/api/v1/appointments/add_appointment",
        _data
      );
      if (response.data?.status === "success") {
        navigate(
          `/Confirmation?service=${data.service.name}&branch=${
            data.location.name
          }&appointment_time=${date.toISOString()}&id=${response.data.data.id}&lang=${language}`
        );
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const validateData = () => {
    let _errors = {};
    if (!data?.service || !data?.service?.id) {
      _errors["service"] = languages[language].serviceRequired;
    }
    if (!data?.location || !data?.location?.id) {
      _errors["location"] = languages[language].locationRequired;
    }
    if (!data?.date || data?.date === "") {
      _errors["date"] = languages[language].dateRequired;
    }
    if (!data?.time || data?.time === "") {
      _errors["time"] = languages[language].timeRequired;
    }
    if (Object.keys(_errors).length === 0) {
      addAppointment();
    } else {
      setErrors(_errors);
    }
  };

  return (
    <div>
      <Header />
      <div className="flex flex-col px-5 max-w-[749px] text-stone-900 mt-28">
        <div className="flex justify-center items-center w-screen mt-10">
          <div className="self-end text-5xl font-bold text-center w-[567px] max-md:max-w-full max-md:text-4xl">
            <span className="text-indigo-500">{languages[language].saveTime}</span> {languages[language].andBookNow}
          </div>
        </div>
        <div className="mt-12 w-full text-2xl font-semibold max-md:mt-10 max-md:max-w-full ml-24">
          {languages[language].welcome} {firstname},
        </div>
      </div>
      <LocationDropdown data={data} errors={errors} setData={setData} />
      <Calander
        className="ml-28"
        data={data}
        errors={errors}
        setData={setData}
      />

      <div className="flex flex-row items-center justify-center mt-20">
        <button
          className="bg-indigo-500 font-bold leading-6 text-white px-8 py-3 rounded-md w-1/2"
          onClick={validateData}
          disabled={isLoading}
        >
          {isLoading ? languages[language].saving : languages[language].saveAppointment}
        </button>
      </div>
      <Footer />
    </div>
  );
}

export default Appointment;
