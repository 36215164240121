import React, { useState, useEffect } from "react";
import "react-calendar/dist/Calendar.css";
import DatePicker from "react-calendar";
import ErrorMessage from "../Commons/ErrorMessage";
import axios from "axios";

const Calendar = ({ setData, data, errors }) => {
  const [availableTimes, setAvailableTimes] = useState([]);
  const [loading, setLoading] = useState(false);

  const disabledDates = ["2024-06-10", "2024-06-05", "2024-06-07"];

  const fetchAvailableTimeSlots = async (date) => {
    setLoading(true);
    try {
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;

      console.log(formattedDate);

      // Fetch the data from the API
      const response = await axios.get(
        `https://qetero.com/api/v1/appointments/availableTimeSlotsByDate?branch_id=f568edee-7fed-499e-968f-6f237adb0485&branch_service_id=e61572b5-f716-432f-813b-1977baf0e6fb&appointment_date=2024-09-10`
      );

      // Extract the data
      const _data = response.data.data;

      // Check if the date exists in the response data
      //const availableTimesForDate = _data[formattedDate]?.availableTimes || [];
      const prepareTime = (time1, time2) => {
        let [_time1, _time2] = [time1.split(":"), time2.split(":")];
        let ampm = "Morning";
        _time1[0] = String(parseInt(_time1) - 6).padStart("0");
        _time2[0] = parseInt(_time2[0]) - 6;
        if (_time2[0] > 6) {
          ampm = "Afternoon";
        }

        _time2[0] = String(_time2[0]).padStart("0");

        return `${_time1.join(":")} - ${_time2.join(":")} (${ampm})`;
      };
      // Format the available times
      const _availableTimes = _data.map((timeSlot) => ({
        time: prepareTime(timeSlot.time_from, timeSlot.time_to),
        remainingSlots: timeSlot.remaining_slots,
        duration_id: timeSlot.duration_id,
        time_from: timeSlot.time_from,
      }));

      // Update the state with the available times
      setAvailableTimes(_availableTimes);
    } catch (error) {
      console.error("Error fetching available time slots:", error);
    } finally {
      setLoading(false);
    }
  };

  const isDateDisabled = (date) => {
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
    const isWeekend = date.getDay() === 0 || date.getDay() === 6; // 0 is Sunday, 6 is Saturday
    return disabledDates.includes(formattedDate) || isWeekend;
  };

  const handleDateChange = (date) => {
    setData({ ...data, date: date, time: null });
    fetchAvailableTimeSlots(date);
  };

  const handleTimeSelection = (time) => {
    setData({
      ...data,
      time: time,
    });
  };

  return (
    <div className="calendar-container p-4 bg-white rounded-lg flex flex-col md:flex-row items-center mt-6 gap-28 px-4 md:px-28">
      <div>
        <ErrorMessage message={errors?.date} />
        <DatePicker
          value={data.date}
          onChange={handleDateChange}
          minDate={new Date()}
          tileDisabled={({ date }) => isDateDisabled(date)}
          className="w-full h-80 p-2 border border-gray-300 rounded-lg"
        />
      </div>

      <div className="available-times">
        {loading ? (
          <div className="text-xl font-semibold mb-2">
            Fetching available time slots...
          </div>
        ) : (
          <>
            <ErrorMessage message={errors?.time} />
            <h3 className="text-xl font-semibold mb-2">Available Times:</h3>
            {availableTimes.length > 0 ? (
              <ul className="space-y-2">
                {availableTimes.map((time, index) => (
                  <li key={index} className="flex items-center space-x-2">
                    <input
                      type="radio"
                      name="available-time"
                      value={time.time}
                      checked={data.time?.time === time.time}
                      onChange={() => handleTimeSelection(time)}
                      className="form-radio h-4 w-4 text-indigo-600"
                    />
                    <label className="text-gray-700">
                      {time.time} ({time.remainingSlots}{" "}
                      {time.remainingSlots === 1 ? "slot" : "slots"})
                    </label>
                  </li>
                ))}
              </ul>
            ) : (
              <div className="text-gray-700">
                No available slots for the selected date.
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Calendar;
