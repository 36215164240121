import * as React from "react";
import { useNavigate } from "react-router-dom";

function PINReset() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = React.useState(false);
  
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleResetClick = () => {
    setShowModal(true);
  };
  return (
    <div className="flex flex-col px-16 py-6 bg-white max-md:px-5">
      <div className="flex gap-2.5 self-start text-xl font-medium text-center text-indigo-500 whitespace-nowrap">
        <img
          loading="lazy"
          srcSet="./image1.png"
          className="shrink-0 aspect-[0.96] w-[57px]"
        />
        <div className="my-auto">DARS.</div>
      </div>
     <div className="self-center mt-11 text-3xl font-bold text-black max-md:mt-10 max-md:self-center">
  PIN Reset
</div>
      <div className="self-center mt-11 w-full max-w-[947px] max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex justify-start w-full max-md:ml-0 max-md:w-full">
            <img
              loading="lazy"
              srcSet="/images11.jpg"
              className="w-[59%] aspect-[0.96] max-md:mt-10 max-md:max-w-full"
            />
          </div>
          <div className="flex flex-col ml-5 w-[41%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow py-0.5 text-xs max-md:mt-10">
                         <label htmlFor="newpin" className="block text-gray-700  mb-2">New PIN</label>
            <input type="password" id="newpin" name="newpin" className="  w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
              <div className="shrink-0 mt-2.5 h-0.5 bg-indigo-500" />
                         <label htmlFor="confirmpin" className="block text-gray-700 mt-8">Confirm PIN</label>
            <input type="password" id="confirmpin" name="confirmpin" className="w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />   
              <div className="shrink-0 mt-1.5 h-0.5 bg-indigo-500" />
              <button className="justify-center items-center px-12 py-3.5 mt-36 text-sm font-semibold text-center text-white whitespace-nowrap bg-indigo-500 rounded-lg max-md:px-5 max-md:mt-10"  onClick={handleResetClick}>
               Reset
              </button>
             
            </div>
                {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-indigo-900 bg-opacity-40 backdrop-blur-sm">
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <h2 className="text-lg font-bold mb-4">PIN Reset Successful</h2>
            <p className="mb-4">Your PIN has been successfully reset.</p>
            <button
              className="bg-indigo-500 text-white px-4 py-2 rounded-lg"
              onClick={() => {
                handleCloseModal();
                navigate("/Login");
              }}
            >
              Continue
            </button>
          </div>
        </div>
      )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PINReset;


