import * as React from "react";
import { useNavigate } from "react-router-dom";
function ForgetPIN() {
    const navigate=  useNavigate();
  return (
    <div className="flex flex-col px-16 py-6 bg-white max-md:px-5">
      <div className="flex gap-2.5 self-start text-xl font-medium text-center text-indigo-500 whitespace-nowrap">
        <img
          loading="lazy"
          srcSet="./image1.png"
          className="shrink-0 aspect-[0.96] w-[57px]"
        />
        <div className="my-auto">DARS.</div>
      </div>
      <div className="self-center mt-11 text-3xl font-bold text-black max-md:mt-10">
        PIN Reset
      </div>
      <div className="self-center mt-11 w-full max-w-[947px] max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
       <div className="flex justify-start w-full max-md:ml-0 max-md:w-full">
            <img
              loading="lazy"
              srcSet="/images11.jpg"
              className="w-[59%] aspect-[0.96] max-md:mt-10 max-md:max-w-full"
            />
          </div>
          <div className="flex flex-col ml-5 w-[41%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow py-0.5 text-xs max-md:mt-10">            
           <div className="block text-gray-700 mt-8 ">We have sent an OTP to your phone number</div>
            <input type="number" id="OTP" name="OTP" className="w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                <div className="shrink-0 mt-2.5 h-0.5 bg-indigo-500" />
                <button className="justify-center items-center px-12 py-3.5 mt-28 text-sm font-semibold text-center text-white whitespace-nowrap bg-indigo-500 rounded-lg max-md:px-5 max-md:mt-10" onClick={() => navigate('/PINReset')}>
                Continue
              </button>
              <div className="flex justify-center mt-10  text-sm  cursor-pointer text-indigo-500 font-bold">OTP not recieved ?</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgetPIN;


