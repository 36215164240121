import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landingpage from "./Components/LandingPage/Landingpage";
import Appointment from "./Components/Appointment/Appointment";
import Confirmation from "./Components/Appointment/Confirmation";
import SuccessMessage from "./Components/Commons/SuccessMessage";
import AboutUs from "./Components/Aboutus/Aboutus";
import ContactUs from "./Components/Contactus/Contactus";
import Profile from "./Components/Profile/Profile";
import SignUp from "./Components/SignUp/SignUp";
import Login from "./Components/LogIn/Login";
import PIN from "./Components/LogIn/PIN";
import ForgetPIN from "./Components/LogIn/ForgetPIN";
import PINReset from "./Components/LogIn/PINReset";
import OTPForm from "./Components/LogIn/OtpForm";
import QeteroLandingPage from "./Components/LandingPage/QeteroLandingPage";
import OtpFormSignUp from "./Components/LogIn/OtpFormSignUp";
import { AuthProvider } from "./contexts/authContext";
import BookingHistory from "./Components/Appointment/BookingHistory";
import VConsole from 'vconsole';
function App() {
  const vConsole = new VConsole();
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/Landingpage" element={<Landingpage />} />
          <Route path="/" element={<QeteroLandingPage />} />
          <Route path="/Appointment" element={<Appointment />} />
          <Route path="/Landingpage/Appointment" element={<Appointment />} />
          <Route path="/Confirmation" element={<Confirmation />} />
          <Route path="/Aboutus" element={<AboutUs />} />
          <Route path="/Contactus" element={<ContactUs />} />
          <Route path="/BookingHistory" element={<BookingHistory />} />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/SignUp" element={<SignUp />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/otp" element={<OTPForm />} />
          <Route path="/OtpSignUp" element={<OtpFormSignUp />} />
          <Route path="/PIN" element={<PIN />} />
          <Route path="/ForgetPIN" element={<ForgetPIN />} />
          <Route path="/PINReset" element={<PINReset />} />
          <Route path="/Success" element={<SuccessMessage />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
