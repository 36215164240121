import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import "../../index.css";
import { FaBars, FaTimes } from "react-icons/fa";
import { AuthContext } from "../../contexts/authContext";

function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [language, setLanguage] = useState("en");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { user, setUser, setToken } = useContext(AuthContext);

  console.log("user", user);

  const linkClasses = (path) => {
    const isActive = location.pathname === path;
    return isActive
      ? "text-black font-bold text-base my-2"
      : "hover:text-black text-base my-2 transition-colors duration-200 ease-in-out";
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    setUser(null);
    setToken(null);
    navigate("/");
  };

  const handleNavigation = (path) => {
    const token = localStorage.getItem("token");
    if (path === "/appointment" && !token) {
      navigate("/Login");
    } else {
      navigate(path);
    }
    setMenuOpen(false);
  };

  // Retrieve language preference from localStorage on mount
  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      setLanguage(storedLanguage);
    }
  }, []);

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    localStorage.setItem("language", selectedLanguage);
    setLanguage(selectedLanguage);
    // Here you would typically trigger a language change in your app's localization
  };

  return (
    <header className="fixed top-0 left-0 w-full bg-white z-50">
      <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-2">
        {/* Logo */}
        <div className="flex items-center">
          <img
            loading="lazy"
            src="./WhatsApp_Image_2024-09-10_at_09.42.40_e45954ba-removebg-preview.png"
            className="w-[72px] h-20"
            alt="DARS Logo"
          />
        </div>

        {/* Space filler */}
        <div className="flex-grow"></div>

        {/* Wrapper for Desktop Menu and Language Selector */}
        <div className="flex items-center space-x-4">
          {/* Desktop Menu */}
          <nav className="hidden md:flex items-center space-x-4">
            <button onClick={() => handleNavigation("/")} className={linkClasses("/")}>
              Home
            </button>
            <button onClick={() => handleNavigation("/aboutus")} className={linkClasses("/aboutus")}>
              About Us
            </button>
            <button onClick={() => handleNavigation("/contactus")} className={linkClasses("/contactus")}>
              Contact Us
            </button>
            {user && (
              <>
                <Link to="/Profile" className={linkClasses("/Profile")}>
                  Profile
                </Link>
                <Link to="/BookingHistory" className={linkClasses("/BookingHistory")}>
                  Booking History
                </Link>
              </>
            )}
          </nav>

          <div className="hidden md:flex gap-5 items-center">
            {user ? (
              <div className="relative">
                <div
                  className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-blue-200 rounded-full hover:border-spacing-1"
                  onClick={toggleDropdown}
                >
                  <a className="font-medium text-gray-600 ">
                    {user?.firstname?.charAt(0)?.toUpperCase()}
                    {user?.lastname?.charAt(0)?.toUpperCase()}
                  </a>
                </div>
                {dropdownOpen && (
                  <div className="absolute right-0 mt-2 bg-white rounded-md shadow-xl z-1000">
                    <div className="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44">
                      <div className="px-4 py-3 text-sm text-gray-900">
                        <div>
                          {user?.firstname} {user?.lastname}
                        </div>
                        <div className="font-medium truncate">{user?.mobile}</div>
                      </div>
                      <div className="py-1">
                        <a
                          href="#"
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600  dark:hover:text-white"
                          onClick={handleLogout}
                        >
                          Sign out
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <button
                className="bg-indigo-500 text-white rounded-md px-4 py-2 hover:bg-indigo-600 transition-colors duration-200 ease-in-out"
                onClick={() => navigate("/Login")}
              >
                Login
              </button>
            )}
          </div>

          {/* Language Selector */}
          <select
            value={language}
            onChange={handleLanguageChange}
            className="p-2 border rounded"
          >
            <option value="en">English</option>
            <option value="am">አማርኛ</option>
          </select>

          {/* Menu Toggle Button (Mobile) */}
          <div className="md:hidden flex items-center">
            <button onClick={toggleMenu} className="text-2xl">
              {menuOpen ? <FaTimes /> : <FaBars />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {menuOpen && (
        <div className="md:hidden bg-white shadow-md p-5 flex flex-col items-center">
          <button
            className="w-full text-center text-base my-2 hover:text-black transition-colors duration-200 ease-in-out"
            onClick={() => handleNavigation("/")}
          >
            Home
          </button>
          <button
            className="w-full text-center text-base my-2 hover:text-black transition-colors duration-200 ease-in-out"
            onClick={() => handleNavigation("/aboutus")}
          >
            About Us
          </button>
          <button
            className="w-full text-center text-base my-2 hover:text-black transition-colors duration-200 ease-in-out"
            onClick={() => handleNavigation("/contactus")}
          >
            Contact Us
          </button>
          {user ? (
            <>
              <button
                className="w-full text-center text-base my-2 hover:text-black transition-colors duration-200 ease-in-out"
                onClick={() => handleNavigation("/Profile")}
              >
                Profile
              </button>
              <button
                className="w-full text-center text-base my-2 hover:text-black transition-colors duration-200 ease-in-out"
                onClick={() => handleNavigation("/BookingHistory")}
              >
                Booking History
              </button>
            </>
          ) : (
            <button
              className="w-full text-center text-base my-2 hover:text-black transition-colors duration-200 ease-in-out"
              onClick={() => handleNavigation("/login")}
            >
              Login
            </button>
          )}
        </div>
      )}
    </header>
  );
}

export default Header;
