import React from "react";
import "../../index.css";
import {
  UserOutlined,
  BranchesOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Cards from "./Cards";
import Services from "./Services";
import { useNavigate } from "react-router-dom";

function LandingPage() {
  const navigate = useNavigate();

  const handleBookNow = () => {
    navigate("./Appointment");
  };

  return (
    <div>
      <Header />
      <div className="px-4">
        <div className="flex flex-col md:flex-row mt-20 md:ml-28 md:max-w-[740px] ">
          <div className="text-5xl font-bold text-stone-900 md:text-4xl ">
            <p className="leading-relaxed">
              Save Time : Your
              <br />
              Convenient Way to
              <br />
              Schedule Services at DARS
            </p>
          </div>
        </div>

        <button
          className="justify-center items-center px-10 py-3 text-base font-bold leading-6 text-white bg-indigo-500 rounded-lg max-w-[250px] mt-10 md:ml-28 shadow-lg shadow-indigo-400"
          style={{ width: "180px", height: "46px" }}
          onClick={handleBookNow}
        >
          Book Now
        </button>

        <div className="grid grid-cols-1 md:grid-cols-3 divide-y md:divide-y-0 md:divide-x mt-16 gap-8">
          <div className="flex items-center justify-center md:ml-28">
            <div className="text-4xl text-indigo-500">
              <UserOutlined />
            </div>
            <div className="ml-8 md:ml-32">
              <div className="text-2xl font-bold leading-8 text-slate-900">
                15,000+
              </div>
              <div className="mt-5 text-xl leading-8 text-gray-600">
                Daily Appointments
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <div className="text-4xl text-indigo-500">
              <BranchesOutlined />
            </div>
            <div className="ml-8 md:ml-32">
              <div className="text-2xl font-bold leading-8 text-slate-900">
                25+
              </div>
              <div className="mt-5 text-xl leading-8 text-gray-600">
                Branches in Ethiopia
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <div className="text-4xl text-indigo-500">
              <UnorderedListOutlined />
            </div>
            <div className="ml-8 md:ml-32">
              <div className="text-2xl font-bold leading-8 text-slate-900">
                40+
              </div>
              <div className="mt-5 text-xl leading-8 text-gray-600">
                Services available
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col px-5 mt-16">
          <div className="w-full max-md:pr-5 max-md:max-w-full">
            <div className="flex flex-col md:flex-row gap-5">
              <Cards
                title="Skip waiting"
                description="No more waiting in line! Schedule your appointment online at any time, day or night, and avoid the hassle of traditional queuing."
                icon="clock"
              />
              <Cards
                title="Confirm and Go"
                description="Before finalizing your appointment, take a moment to meticulously review all the details online. to ensure a smooth experience."
                icon="circle"
              />
              <Cards
                title="Save Your Time"
                description="We understand your life is busy, packed with commitments and responsibilities. So make your choice today "
                icon="calander"
              />
              <Cards
                title="Streamlined Service"
                description="With just a few clicks, you can schedule your appointment and get back to your busy day. For your effectivness and efficency"
                icon="mobileoutlined"
              />
            </div>
          </div>
          <div className="self-center mt-28 text-4xl font-semibold text-stone-900">
            Our Services
          </div>
          <div className="px-0">
            <div className="flex flex-col md:flex-row gap-5">
              <Services
                title="Sales"
                description="Vehicle sales, residence sales, business sales, other property sales, vehicle spare parts sales, lease sales"
              />
              <Services
                title="Gifts"
                description="Vehicle gifts, residence gifts, business gifts, other property gifts, vehicle spare parts gifts, lease gifts"
              />
              <Services
                title=" Representation"
                description="General representation and also full general representation and other related services."
              />
              <Services
                title="Family Representation"
                description="Family representation and full family representation and other family representation issues"
              />
            </div>
          </div>

          <div className="self-center mt-28 text-4xl font-semibold text-stone-900">
            Latest News
          </div>
          <div className="flex flex-wrap justify-center gap-8 px-4 mt-16">
            {[
              {
                img: "./dars-tele.jpg",
                title: "DARS and Ethiotelecom Agreement",
                description:
                  "Save time with streamlined services. No more waiting in line! Schedule your appointment online at any time, day or night.",
              },
              {
                img: "./dars-tele.jpg",
                title: "DARS and Ethiotelecom Agreement",
                description:
                  "Save time with streamlined services. No more waiting in line! Schedule your appointment online at any time, day or night.",
              },
              {
                img: "./dars-trust.jpg",
                title: "Trustworthy Services",
                description:
                  "Save time with streamlined services. No more waiting in line! Schedule your appointment online at any time, day or night.",
              },
              {
                img: "./dars-blood.jpg",
                title: "We are Responsible",
                description:
                  "Save time with streamlined services. No more waiting in line! Schedule your appointment online at any time, day or night.",
              },
            ].map((news, index) => (
              <div
                key={index}
                className="bg-white  hover:shadow-xl transition-shadow duration-300 transform hover:scale-105 w-full md:w-80"
              >
                <img
                  className="w-full h-48  object-cover"
                  src={news.img}
                  alt={news.title}
                />
                <div className="p-6">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">
                    {news.title}
                  </h3>
                  <p className="text-base text-gray-600">{news.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default LandingPage;
